@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.view-layout {
  display: flex;
  flex-flow: row nowrap;
  padding-top: vw_d(43);
  column-gap: vw_d(73);

  @include screen('mobile') {
    width: 100%;
    flex-flow: column wrap;
    column-gap: inherit;
    padding-top: vw(25);
  }

  .left {
    position: relative;
    width: vw_d(555);
    margin: vw_d(9) 0 0;

    @include screen('mobile') {
      width: vw(180);
      margin: 0 auto;
      order: 1;
    }
  }

  .right {
    width: vw_d(696);

    @include screen('mobile') {
      width: 100%;
      order: 2;
    }
  }
}
