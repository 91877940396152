@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.subtitle {
  font-family: $bold-font;
  font-size: vw_d(40);
  line-height: vw_d(33);
  margin-top: vw_d(-6);
  text-align: center;
  color: $red;
  padding-top: vw_d(20);
  padding-bottom: vw_d(28);
  padding-right: vw_d(28);

  @include screen('mobile') {
    padding: vw(18) vw(10) vw(20);
    font-size: vw(23);
    line-height: vw(21);
  }
}

.title {
  margin-top: vw_d(-20);
}

.checkboxes {
  width: vw_d(662);
  margin-right: vw_d(2);
  box-sizing: border-box;

  @include screen('mobile') {
    width: 100%;
    margin-right: 0;
    padding: 0 vw(20);
  }

  .ch2 {
    margin-top: vw_d(20);
    font-size: vw_d(12);
    line-height: 1;

    @include screen('mobile') {
      margin-top: vw(20);
      font-size: vw(12);
    }
  }
}

.content {
  position: relative;
  font-family: $regular-font;
  font-size: vw_d(30);
  line-height: vw_d(33);
  text-align: center;
  color: black;
  margin-bottom: vw_d(35);
  padding-right: vw_d(28);

  @include screen('mobile') {
    margin-bottom: vw(15);
    padding: 0 vw(10);
    font-size: vw(18);
    line-height: vw(18);
  }

  .block {
    margin: 0 0 vw_d(27);

    @include screen('mobile') {
      margin: 0 0 vw(22);
    }

    &:nth-child(2) {
      margin: 0 vw_d(-23) vw_d(36) 0;

      @include screen('mobile') {
        margin: 0 vw(-5) vw(22) 0;
      }

      h5 {
        padding-right: vw_d(23);
        font-size: vw_d(28);
        line-height: vw_d(31);

        @include screen('mobile') {
          padding-right: vw(20);
          font-size: vw(18);
          line-height: vw(18);
        }
      }

      p {
        font-size: vw_d(28);
        line-height: vw_d(31);

        @include screen('mobile') {
          font-size: vw(18);
          line-height: vw(18);
        }
      }
    }

    h5 {
      font-family: $bold-font;
      font-size: vw_d(30);
      line-height: 1;
      text-align: center;
      color: black;

      @include screen('mobile') {
        padding: 0 vw(20);
        font-size: vw(18);
      }
    }

    p {
      font-size: vw_d(30);
      line-height: 1;

      @include screen('mobile') {
        font-size: vw(18);
      }

      span {
        position: relative;
        display: block;
        padding-right: vw_d(30);

        &:before {
          content: '';
          position: absolute;
          right: vw_d(10);
          top: vw_d(18);
          width: vw_d(7);
          height: vw_d(7);
          background-color: #e51e1e;
          border-radius: 50%;

          @include screen('mobile') {
            display: none;
          }
        }
      }

      &.flex {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .error-msg {
    position: absolute;
    bottom: vw_d(-20);
    left: 50%;
    transform: translate(-50%, 0);
    font-family: $regular-font;
    font-size: vw_d(12);
    line-height: 1;
    color: $red;

    @include screen('mobile') {
      bottom: initial;
      left: initial;
      transform: none;
      position: relative;
      margin-top: vw(10);
      font-size: vw(12);
    }
  }
}

.button {
  margin: 0 auto;
  transform: translateX(vw_d(-15));

  @include screen('mobile') {
    transform: translateX(0);
    padding-left: vw(25);
  }

  .icon {
    position: absolute;
    top: 50%;
    left: vw_d(45);
    transform: translate(0, -55%);
    margin: vw_d(3) vw_d(5) 0 0;

    @include screen('mobile') {
      left: vw(29);
      margin: vw(1) vw(5) 0 0;
    }

    svg {
      width: vw_d(38);
      height: vw_d(38);

      @include screen('mobile') {
        width: vw(26);
        height: vw(26);
      }
    }
  }
}
