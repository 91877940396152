@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.subtitle {
  padding-top: vw_d(26);
  padding-bottom: vw_d(25);
  padding-right: vw_d(5);
  font-family: $bold-font;
  font-size: vw_d(36);
  line-height: vw_d(33);
  text-align: center;
  color: $red;

  @include screen('mobile') {
    padding-top: vw(13);
    padding-bottom: vw(11);
    font-size: vw(25);
    line-height: vw(33);
  }

  span {
    display: inline-block;
    padding-right: vw_d(8);
    color: #000;
    @include screen('mobile') {
      padding-right: vw(8);
    }
  }
}

.content {
  .steps {
    list-style: none;
    margin-right: vw_d(-18);

    @include screen('mobile') {
      margin: 0;
      margin-right: vw(4);
      padding-right: 0;
    }
    li {
      font-family: $regular-font;
      font-size: vw_d(25);
      line-height: vw_d(28);
      text-align: center;
      color: #000;

      @include screen('mobile') {
        font-size: vw(18);
        line-height: vw(18);
      }

      &:last-child {
        padding-right: vw_d(22);
        @include screen('mobile') {
          padding-right: vw(22);
        }
      }
    }
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: vw_d(13);
    padding-right: vw_d(16);
    padding-bottom: vw_d(21);
    background-image: linear-gradient(to right, rgba(217, 0, 13, 1) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: vw_d(15) vw_d(2);
    background-repeat: repeat-x;

    @include screen('mobile') {
      column-gap: vw(10.88);
      margin: 0 vw(10);
      padding-top: vw(10);
      padding-bottom: vw(24);
      background-size: vw(15) vw(1);
    }

    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: vw_d(120);
      height: vw_d(32);
      border-radius: vw_d(16);
      background-color: rgba(255, 0, 0, 0.04);
      font-family: $regular-font;
      font-size: vw_d(17);
      color: #000;

      @include screen('mobile') {
        width: vw(100.41);
        height: vw(26.78);
        border-radius: vw(14);
        font-size: vw(14.23);
      }
    }
  }

  .final-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: vw_d(20) vw_d(12) vw_d(38) 0;
    background-image: linear-gradient(to right, rgba(217, 0, 13, 1) 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: vw_d(15) vw_d(2);
    background-repeat: repeat-x;

    @include screen('mobile') {
      margin: 0 vw(10);
      padding: vw(20) vw(0) vw(25);
      background-size: vw(15) vw(1);
    }

    .text {
      padding-bottom: vw_d(27);
      font-family: $regular-font;
      font-size: vw_d(25);
      line-height: vw_d(28);
      color: #000;
      @include screen('mobile') {
        padding-bottom: vw(11);
        font-size: vw(18);
        line-height: vw(18);
        text-align: center;
      }

      br {
        @include screen('mobile') {
          display: none;
        }
      }
    }

    .button {
      margin-right: vw_d(17);
      padding: 0 vw_d(35);

      @include screen('mobile') {
        margin-right: vw(10);
        padding: 0 vw(25);
      }
    }
  }

  .register {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: vw_d(6.46);
    padding: vw_d(42) vw_d(10) 0 0;

    @include screen('mobile') {
      flex-wrap: wrap;
      padding: vw(19) vw(16) vw(10) vw(10);
    }

    .text {
      font-family: $regular-font;
      font-size: vw_d(22);
      line-height: vw_d(23);
      width: vw_d(460);
      color: #000;

      @include screen('mobile') {
        font-size: vw(17);
        line-height: vw(19);
        width: 100%;
        text-align: center;
      }

      span {
        font-family: $bold-font;
        color: $red;
      }
    }

    .button {
      margin-top: vw_d(5);
      @include screen('mobile') {
        margin-top: vw(20);
        margin-right: vw(-5);
      }
    }
  }
}

.result-image {
  width: 100%;
  display: block;
}
