@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;700;900&display=swap');

@font-face {
  font-family: 'Yed_HaimMF_Narrow';
  src: url('/src/assets/fonts/Yed HaimMF Narrow.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Haimhagilda-Tzar';
  src: url('/src/assets/fonts/Haimhagilda-Tzar.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'HaimNarrowLight-Regular_MFW';
  src: url('/src/assets/fonts/HaimNarrowLight-Regular_MFW.eot');
  src: url('/src/assets/fonts/HaimNarrowLight-Regular_MFW.svg') format('svg');
  src: url('/src/assets/fonts/HaimNarrowLight-Regular_MFW.ttf') format('truetype');
  src: url('/src/assets/fonts/HaimNarrowLight-Regular_MFW.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poalim-Regular';
  src: url('/src/assets/fonts/Poalim-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poalim-Light';
  src: url('/src/assets/fonts/Poalim-Light.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poalim-Bold';
  src: url('/src/assets/fonts/Poalim-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
