@import 'src/styles/theme.scss';

.lds-ring {
  display: inline-block;
  position: relative;
  width: vw_d(30);
  height: vw_d(30);

  &.white {
    div {
      border: vw_d(4) solid #e2d5d5;
      border-color: #fff transparent transparent transparent;
    }
  }

  &.red {
    div {
      border: vw_d(4) solid $red;
      border-color: $red transparent transparent transparent;
    }
  }
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: vw_d(30);
  height: vw_d(30);
  margin: vw_d(4);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
