@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.checkbox {
  font-family: $regular-font;
  font-size: vw_d(11);
  line-height: vw_d(10);
  color: black;

  @include screen('mobile') {
    font-size: vw(11);
    line-height: vw(10);
  }

  a {
    color: $red;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &.error {
    .checkmark {
      border: 1px solid $red;
    }
  }

  label {
    display: flex;
    flex-flow: row nowrap;
    column-gap: vw_d(7);
    cursor: pointer;
    text-align: right;

    @include screen('mobile') {
      column-gap: vw(7);
    }
  }

  input {
    display: none;

    &:checked ~ .checkmark:after {
      display: block;
    }
  }

  .checkmark {
    margin-top: vw_d(3);
    width: vw_d(13);
    height: vw_d(13);
    box-sizing: border-box;
    border: 1px solid black;
    position: relative;
    flex-shrink: 0;

    @include screen('mobile') {
      margin-top: vw(3);
      width: vw(13);
      height: vw(13);
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      width: vw_d(3);
      height: vw_d(6);
      border: solid black;
      border-width: 0 vw_d(2) vw_d(2) 0;
      transform: translate(-50%, calc(-50% - 1px)) rotate(45deg);

      @include screen('mobile') {
        width: vw(3);
        height: vw(6);
        border: solid black;
        border-width: 0 vw(2) vw(2) 0;
      }
    }
  }
}
