@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.content-area {
  position: absolute;
  font-family: $haimYedNarrow;
  padding: vw_d(8);
  box-sizing: border-box;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  &.with-value {
    padding: 0;
  }

  @include screen('mobile') {
    padding: vw(2);
  }

  .placeholder {
    width: 100%;

    p {
      &:nth-child(2) {
        font-size: vw_d(27);
        line-height: vw_d(22);

        @include screen('mobile') {
          font-size: vw(9);
          line-height: vw(9);
          letter-spacing: vw(-0.3);
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    border: vw_d(2) dashed;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    @include screen('mobile') {
      border: vw(0.6) dashed;
    }

    &.right-align {
      span {
        padding: 0 vw_d(20);
        text-align: right;

        @include screen('mobile') {
          padding: 0 vw(5);
        }
      }
    }

    span {
      width: 100%;
    }
  }
}
