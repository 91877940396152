@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.upload-input {
  position: relative;
  background-color: #e8eef1;
  height: vw_d(53);
  border-radius: vw_d(27);

  @include screen('mobile') {
    height: vw(40);
    border-radius: vw(20);
  }

  &.disabled {
    pointer-events: none;
  }

  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    input {
      display: none;
    }
  }

  .info {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    color: black;
    display: flex;
    align-items: center;
    column-gap: vw_d(7);
    padding: 0 vw_d(25);
    pointer-events: none;

    @include screen('mobile') {
      column-gap: vw(5);
      padding: 0 vw(18);
      box-sizing: border-box;
    }

    .notes {
      font-family: $regular-font;
      font-size: vw_d(22);
      line-height: 1;
      margin: vw_d(-3) 0 0 0;

      @include screen('mobile') {
        font-size: vw(16);
        margin: vw(-3) 0 0 0;
      }
    }

    .accept-files {
      font-family: $light-font;
      font-size: vw_d(18);
      margin: vw_d(-2) 0 0 0;
      line-height: 1;
      color: #747373;

      @include screen('mobile') {
        font-size: vw(13);
        margin: vw(-2) 0 0 0;
      }
    }

    .error {
      font-family: $light-font;
      font-size: vw_d(18);
      margin: vw_d(-2) 0 0 0;
      color: $red;

      @include screen('mobile') {
        font-size: vw(13);
        margin: vw(-3) 0 0 0;
      }
    }
  }

  .add-btn {
    position: absolute;
    top: 50%;
    left: vw_d(5);
    background-color: $red;
    width: vw_d(42);
    height: vw_d(42);
    border-radius: 50%;
    transform: translate(0, -50%);
    color: white;
    display: flex;
    align-items: center;
    font-size: vw_d(30);
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 0.9;
    }

    @include screen('mobile') {
      width: vw(31);
      height: vw(31);
      font-size: vw(30);
      left: vw(5);
    }

    span {
      margin-top: vw_d(-6);

      @include screen('mobile') {
        margin-top: vw(-6);
      }
    }
  }

  .foot-note {
    width: 100%;
    padding-right: vw_d(25);
    font-size: vw_d(18);
    line-height: vw_d(31);
    color: rgba(0, 0, 0, 1);
    font-family: $light-font;
    @include screen('mobile') {
      width: auto;
      position: absolute;
      right: 0;
      top: vw(36);
      padding-right: vw(19);
      font-size: vw(14);
      line-height: vw(31);
    }
  }
}
