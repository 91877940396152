@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 vw_d(38);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  letter-spacing: vw_d(1.6);
  cursor: pointer;
  width: fit-content;
  min-width: vw_d(150);
  position: relative;
  transition: opacity 0.2s ease-in-out;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.size-medium {
    font-size: vw_d(25);
    line-height: 0.9;
    height: vw_d(64);
    padding: 0 vw_d(24);
    border-radius: vw_d(32);

    @include screen('mobile') {
      height: vw(46);
      font-size: vw(18.33);
      padding: 0 vw(25);
      border-radius: vw(23);
    }
  }

  &.size-large {
    height: vw_d(74);
    font-size: vw_d(30);
    border-radius: vw_d(37);
    min-width: vw_d(280);

    @include screen('mobile') {
      height: vw(48.47);
      font-size: vw(22);
      border-radius: vw(25);
      min-width: vw(184);
    }
  }

  &.size-small {
    height: vw_d(49);
    padding: 0 vw_d(23);
    font-size: vw_d(19.58);
    letter-spacing: vw_d(0.6);
    border-radius: vw_d(26);

    @include screen('mobile') {
      height: vw(46);
      padding: 0 vw(24) 0 vw(22);
      font-size: vw(17.03);
      letter-spacing: vw(0.6);
      border-radius: vw(26);
    }
  }

  &.variant-primary {
    background-color: $red;
    color: white;
  }

  &.variant-secondary {
    color: #ed1c24;
  }

  &.border-dashed {
    border: vw_d(3) dashed $red;

    @include screen('mobile') {
      border: vw(2) dashed #d9000d;
    }
  }

  &.border-solid {
    border: vw_d(3) solid $red;

    @include screen('mobile') {
      border: vw(2) solid $red;
    }
  }

  &:hover {
    opacity: 0.8;

    &:disabled {
      opacity: 0.7;
    }
  }
}
