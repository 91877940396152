@import 'src/styles/functions.scss';

$fontMosesText: 'Frank Ruhl Libre', serif;

$regular-font: 'Poalim-Regular', sans-serif;
$bold-font: 'Poalim-Bold', sans-serif;
$light-font: 'Poalim-Light', sans-serif;

$haimHagildaTzar: 'Haimhagilda-Tzar', sans-serif;
$haimNarrowLight: 'HaimNarrowLight-Regular_MFW', sans-serif;
$haimYedNarrow: 'Yed_HaimMF_Narrow', sans-serif;

//colors

$dark-blue: #03337d;
$red: #d9000d;
$black: #040e1c;
$black-secondary: #1c222e;
$grey-light: #f2f2f2;
$grey-dark: #7d8d96;

//screen resolutions
$media-mobile-small-ultra: 370;
$media-mobile-small-max: 450;
$media-max-mobile: 767;
$media-tablet-max: 1153;
$media-desktop-small-max: 1531;
$media-desktop-medium: 1690;

$media-medium-desktop: '(max-width: #{$media-desktop-medium}px)';
$media-small-desktop: '(max-width: #{$media-desktop-small-max}px)';
$media-tablet: '(max-width: #{$media-tablet-max}px)';
$media-mobile-max: '(max-width: #{$media-max-mobile}px)';
$media-mobile-small: '(max-width: #{$media-mobile-small-max}px)';
$media-mobile-ultra: '(max-width: #{$media-mobile-small-ultra}px)';
