@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.text-area {
  background-color: #e8eef1;
  border-radius: vw_d(20);
  padding: vw_d(12) vw_d(37) vw_d(18) vw_d(17);

  @include screen('mobile') {
    border-radius: vw(15);
    padding: vw(12) vw(19) vw(15) vw(16);
  }

  textarea {
    width: 100%;
    all: unset;
    display: block;
    background: transparent;
    border: none;
    width: 100%;
    resize: none;
    color: black;
    font-family: $regular-font;
    font-size: vw_d(22);
    line-height: 1;
    overflow: hidden;

    @include screen('mobile') {
      font-size: vw(16);
    }

    &::placeholder {
      color: black;
    }
  }

  .info {
    margin-top: vw_d(5);
    color: $red;
    font-size: vw_d(22);

    @include screen('mobile') {
      margin-top: vw(5);
      font-size: vw(16);
    }
  }
}
