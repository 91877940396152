@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: vw_d(80);

  @include screen('mobile') {
    justify-content: space-between;
    column-gap: inherit;
    width: 100%;
    padding: vw(0) vw(22);
    box-sizing: border-box;
  }

  .partner {
    img {
      width: 100%;
      display: block;
      image-rendering: auto;
    }

    &.ynet {
      width: vw_d(83);
      margin-top: vw_d(-18);
      transform: translateX(vw_d(-17));
      @include screen('mobile') {
        width: vw(52.53);
        margin-top: vw(-10);
        transform: translateX(0);
      }
    }
    &.poalim {
      width: vw_d(160);
      margin-top: vw_d(-18);
      @include screen('mobile') {
        width: vw(100);
        margin-top: vw(-11);
      }
    }
    &.hap {
      width: vw_d(104);
      @include screen('mobile') {
        width: vw(70);
        margin-right: vw(-11);
      }
    }
  }
}
