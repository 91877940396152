@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.title {
  padding-right: vw_d(23);
  font-size: vw_d(94);
  line-height: 1;
  letter-spacing: vw_d(-4);
  font-family: $bold-font;
  background: linear-gradient(270.33deg, #000000 10.73%, $red 69.36%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @include screen('mobile') {
    padding-right: 0;
    font-size: vw(47);
    line-height: 1;
    letter-spacing: vw(-1.9);
  }
}
