@import 'src/styles/theme';

.image-template {
  position: relative;

  :global {
    .template-wrapper {
      position: relative;
      width: 562px;
      height: 776px;
    }
  }

  .t-image {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
  }

  .cover-image {
    position: absolute;
    top: 205px;
    left: 31px;
    width: 309px;
    height: 307px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }

  .title {
    position: absolute;
    top: 205px;
    left: 340px;
    width: 192px;
    height: 307px;
    z-index: 2;
    font-size: 66px;
    line-height: 59px;
    letter-spacing: -2px;
    font-family: $haimYedNarrow;
    padding: 5px 4px;
    color: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .subtitle {
    position: absolute;
    top: 521px;
    left: 31px;
    width: 503px;
    height: 49px;
    color: white;
    font-family: $haimYedNarrow;
    font-size: 41px;
    line-height: 38px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    box-sizing: border-box;
  }
}
