@import 'src/styles/theme';
@import 'src/styles/mixins.scss';

.nav-buttons {
  display: flex;
  justify-content: space-between;
  padding: vw_d(2) vw_d(25) 0 vw_d(10);

  @include screen('mobile') {
    padding: 0 vw(22) 0 vw(15);
  }

  .button {
    width: vw_d(226);

    @include screen('mobile') {
      width: vw(161);
    }
  }
}
.icon {
  transform: rotate(-180deg);

  @include screen('mobile') {
  }

  &.next {
    transform: none;
  }

  svg {
    width: vw_d(30);
    height: vw_d(30);

    @include screen('mobile') {
      width: vw(23);
      height: vw(23);
    }
  }
}

.text-field {
  margin-bottom: vw_d(34);

  @include screen('mobile') {
    margin-bottom: vw(15);
  }

  textarea {
    @include screen('mobile') {
      height: vw(49);
    }
  }

  &.subtitle-field {
    textarea {
      @include screen('mobile') {
        height: vw(49);
      }
    }
  }
}

.subtitle {
  color: black;
  text-align: center;
  margin: vw_d(15) 0 vw_d(23);
  padding-right: vw_d(15);

  @include screen('mobile') {
    margin: vw(15) 0 vw(5);
  }

  h3 {
    font-family: $bold-font;
    font-size: vw_d(30);

    @include screen('mobile') {
      font-size: vw(20);
      line-height: vw(33);
    }
  }
}

.image {
  margin-bottom: vw_d(37);

  @include screen('mobile') {
    margin-bottom: vw(39);
  }
}

.inputs {
  padding: 0 vw_d(26) 0 vw_d(10);
  @include screen('mobile') {
    width: 100%;
    padding: 0 vw(22) 0 vw(15);
    box-sizing: border-box;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.info {
  font-family: $regular-font;
  font-size: vw_d(25);
  line-height: vw_d(31);
  text-align: center;
  color: black;
  margin: 0 0 vw_d(40);

  @include screen('mobile') {
    font-size: vw(20);
    line-height: vw(19);
    margin: 0 0 vw(30);
    padding: 0 vw(15);
  }

  a {
    color: $red;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
}
