@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.image-constructor {
  width: vw_d(555);
  position: relative;

  @include screen('mobile') {
    width: 100%;
  }

  img {
    width: 100%;
    display: block;
  }

  .title-area {
    top: vw_d(202);
    left: vw_d(336);
    width: vw_d(190);
    height: vw_d(303);
    font-size: vw_d(65);
    line-height: vw_d(56);
    letter-spacing: vw_d(-1);
    text-align: center;
    box-sizing: border-box;

    @include screen('mobile') {
      top: vw(66);
      left: vw(109);
      width: vw(62);
      height: vw(97.85);
      font-size: vw(19);
      line-height: vw(18);
      letter-spacing: 0;
    }
  }

  .title-area-placeholder {
    font-size: vw_d(45);
    letter-spacing: vw_d(-1);
    line-height: 0.8;
    text-align: center;

    @include screen('mobile') {
      font-size: vw(45);
      font-size: vw(18);
      line-height: vw(14);
      letter-spacing: 0;
    }
  }

  .subtitle-area {
    top: vw_d(514);
    left: vw_d(30);
    width: vw_d(497);
    height: vw_d(49);
    text-align: center;
    font-size: vw_d(39);
    line-height: 1;
    letter-spacing: vw_d(-1);
    box-sizing: border-box;

    @include screen('mobile') {
      top: vw(167);
      left: vw(10);
      width: vw(161);
      height: vw(16);
      font-size: vw(7.14);
      letter-spacing: vw(-0.2);
    }
  }

  .subtitle-area-placeholder {
    font-size: vw_d(28);
    line-height: 1;
    letter-spacing: vw_d(-1);
    text-align: center;

    @include screen('mobile') {
      font-size: vw(11);
      line-height: 1;
      letter-spacing: 0;
    }
  }

  .image-file-area {
    top: vw_d(202);
    left: vw_d(33);
    width: vw_d(303);
    height: vw_d(304);
    font-size: vw_d(45);
    color: #034092;
    text-align: center;
    box-sizing: border-box;

    @include screen('mobile') {
      top: vw(66);
      left: vw(11);
      width: vw(98);
      height: vw(98);
      font-size: vw(16);
    }
  }

  .image-file-placeholder {
    padding: vw_d(5);

    @include screen('mobile') {
      padding: vw(2);
    }
  }

  .sponsor-image {
    position: absolute;
    bottom: vw_d(17);
    left: vw_d(15);
    display: block;
    width: vw_d(103);

    @include screen('mobile') {
      width: vw(103);
      bottom: vw(17);
      left: vw(15);
    }
  }

  .static-title {
    position: absolute;
    top: vw_d(202);
    left: vw_d(336);
    width: vw_d(190);
    height: vw_d(303);
    font-family: $haimYedNarrow;
    font-size: vw_d(65);
    line-height: vw_d(56);
    letter-spacing: vw_d(-1);
    padding: vw_d(10) vw_d(5) 0;
    box-sizing: border-box;
    text-align: center;

    @include screen('mobile') {
      top: vw(66.49);
      left: vw(109);
      width: vw(62);
      height: vw(97);
      font-size: vw(19);
      line-height: vw(17);
      letter-spacing: vw(-0.3);
      padding: vw(5) vw(4);
    }

    span {
      font-weight: 670;
    }
  }

  .static-subtitle {
    position: absolute;
    top: vw_d(514);
    left: vw_d(30);
    width: vw_d(497);
    height: vw_d(49);
    font-family: $haimYedNarrow;
    font-size: vw_d(39);
    line-height: 1;
    letter-spacing: vw_d(-1);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen('mobile') {
      top: vw(167);
      left: vw(10);
      width: vw(161);
      height: vw(16);
      letter-spacing: vw(-0.2);
      font-size: vw(12);
    }
  }
}
