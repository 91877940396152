@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;700;900&display=swap");
@font-face {
  font-family: "Yed_HaimMF_Narrow";
  src: url("/src/assets/fonts/Yed HaimMF Narrow.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haimhagilda-Tzar";
  src: url("/src/assets/fonts/Haimhagilda-Tzar.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "HaimNarrowLight-Regular_MFW";
  src: url("/src/assets/fonts/HaimNarrowLight-Regular_MFW.eot");
  src: url("/src/assets/fonts/HaimNarrowLight-Regular_MFW.svg") format("svg");
  src: url("/src/assets/fonts/HaimNarrowLight-Regular_MFW.ttf") format("truetype");
  src: url("/src/assets/fonts/HaimNarrowLight-Regular_MFW.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poalim-Regular";
  src: url("/src/assets/fonts/Poalim-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poalim-Light";
  src: url("/src/assets/fonts/Poalim-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Poalim-Bold";
  src: url("/src/assets/fonts/Poalim-Bold.ttf");
  font-weight: normal;
  font-style: normal;
}
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

html,
body {
  direction: rtl;
  padding: 0;
  margin: 0;
  font-family: "Poalim-Regular", sans-serif;
  background-color: white;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: unset;
}

h1,
h2,
h3,
h5 {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  border: none;
}

p {
  margin: 0;
}