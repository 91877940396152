@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: vw_d(38);
  z-index: 2;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: $black;
  font-size: vw_d(10);
  font-family: $fontMosesText;
  font-weight: 310;

  @include screen('mobile') {
    height: vw(17.3);
    font-size: vw(10.7);
  }
}
