@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  color: #fff;
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 100% auto;
  position: relative;
  padding-bottom: vw_d(60);

  @include screen('mobile') {
    padding-bottom: vw(42.61);
  }
}
